(function ($) {
  bindMarketSelectorEvents();
})(jQuery);
function getMarketSelectorHtmlCallback() {
  if (breakpoint.value === 'md' || breakpoint.value === 'lg') {
    if ($(".pageHeader[data-transparent]").length) {
      $(".pageHeader").removeClass("is-transparent");
      $(".pageHeader-searchOverlay").removeClass("is-visible");
    }
  }
  if (breakpoint.value === 'xs' || breakpoint.value === 'sm') {
    setTimeout(function () {
      $(".pageHeader-nav.is-visible").scrollTop($(".pageHeader-nav.is-visible")[0].scrollHeight);
    }, 200);
  }
}
function bindMarketSelectorEvents() {
  $(".marketSelector-trigger").click(function (e) {
    e.preventDefault();
    var $marketSelectorPlaceholder = $(this).closest(".marketSelector").find(".marketSelector-dropdownMenu"),
      theme = $("body").data("theme");
    if ($marketSelectorPlaceholder.html().length === 0 || $marketSelectorPlaceholder.hasClass("has-error")) {
      $marketSelectorPlaceholder.html("<li class=\"u-overflowHidden text-center u-padding15\"><img src=\"/ui/" + theme + "/img/loader.svg\" class=\"navigationMainComponent-marketSelectorLoader u-loader\" /></li>");
      $.ajax({
        data: {
          sitecoreItemId: $("body").data("sc-item"),
          sitecoreLanguageId: $("body").data("sc-language"),
          requestUrl: window.location.href
        },
        type: "post",
        url: "/api/marketlanguages/marketlanguageselectorhtml",
        error: function error() {
          $marketSelectorPlaceholder.html("<li class=\"navigationMainComponent-xhrError\">ERROR: Could not load market selector</li>");
        },
        success: function success(data) {
          $marketSelectorPlaceholder.html(data.Html);
          getMarketSelectorHtmlCallback();
        }
      });
    } else {
      getMarketSelectorHtmlCallback();
    }
  });
  $('.marketSelector.dropdown').on('hide.bs.dropdown', function () {
    if (breakpoint.value === 'md' || breakpoint.value === 'lg') {
      if ($(".pageHeader[data-transparent]").length) {
        var serviceMenuHeight = $(".serviceMenu").outerHeight();
        var scrollAmount = $(window).scrollTop();
        if (scrollAmount < serviceMenuHeight) {
          $(".pageHeader").addClass("is-transparent");
        }
      }
    }
  });
}

//Don't close the dropdown if clicking inside the container
$('body').on("click", ".marketSelector .dropdown-menu", function (e) {
  $(this).parent().is(".open") && e.stopPropagation();
});

//Open selection and stop propagation
$('body').on("click", ".marketSelector .dropdown-menu .marketSelector-header", function (e) {
  e.stopPropagation();
  $(this).toggleClass("is-expanded");
});

//Open selection and stop propagation
$('body').on("click", ".marketSelector.marketSelector--standalone .marketSelector-header", function (e) {
  e.stopPropagation();
  $(this).toggleClass("is-expanded");
});

// Set market and langauge cookies
$('body').on("click", ".marketSelector-countryLink", function () {
  setLanguageAndMarketCookies(this);
});
function setLanguageAndMarketCookies(e) {
  var splitted = $(e).attr("href").split("/");
  var language = splitted[1];
  var market = splitted[2];
  var sitecoreItem = $("body").data("sc-item");
  $.ajax({
    type: "POST",
    url: "/post-setmarketandlanguagecookies",
    data: {
      market: market,
      language: language,
      currentSitecoreItem: sitecoreItem
    }
  });
}